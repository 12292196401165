<template>
	<div style="margin-left: 20px;">
		<div v-if="formconfig.formName=='CouponTemplate'" class="select-box">
			<h4>当前已关联商品：<span>{{currentSetProduct}}</span></h4>
			<h4>当前已选商品：<span>{{currentSelectProduct}}</span></h4>
		</div>
		<el-table border ref="singleTable" :data="tableData" highlight-current-row @current-change="handleCurrentChange" style="width: 100%">
			<el-table-column prop="commodityName" label="商品名称"></el-table-column>
		</el-table>
		<el-pagination small :page-size="4" layout="prev, pager, next" @current-change="changePage" :total="total"></el-pagination>
	</div>
</template>

<script>
export default {
	props:{
		selectdata:{
			type:Object,
			default:()=>{}
		},
		formconfig:{
			type:Object,
			default:()=>{}
		},
		opentype: {//类型判断0自定义 1为新建，2为编辑，3为查看
			type: Number,
			default: 0
		},
	},
	data() {
		return {
			tableData: [],
			currentSelectProduct:"",
			currentSetProduct:"",
			page: 1,
			pageSize: 4,
			total:0
		};
	},
	created() {
		this.getProductList();
		if(this.formconfig.formName=='CouponTemplate' && this.opentype==2){
			this.getDetail(this.selectdata.couponTemplateScope)
		}
	},
	methods: {
		handleCurrentChange(val) {
			if(val){
				this.currentSelectProduct = val.commodityName
				this.$emit("getVal",{val:val.commodityId,name:val.commodityName})
			}
		},
		changePage(e) {
			this.page = e
			this.getProductList();
		},
		async getProductList() {
			const res = await this.$axios(
				'post',
				'/sys/commodity/list',
				{
					pageNum: this.page,
					pageSize: this.pageSize
				},
				true
			);
			const { records,total } = res;
			this.total = parseInt(total)
			this.tableData = records || [];
		},
		async getDetail(id){
			const res = await this.$axios("post","/sys/commodity/detail",{commodityId:id},true)
			this.currentSetProduct = res.commodityName
			this.currentSelectProduct = res.commodityName
		}
		
	}
};
</script>

<style lang="scss" scoped>
	.select-box{
		@include flex-style(row,nowrap,space-between,center);
		h4{
			padding: 10px 0;
			font-weight: bold;
			color: #999;
			span{
				color: #666;
			}
		}
	}
</style>
